
.sidenav {
    flex: 0 0 var(--sidenav-width);
    width: var(--sidenav-width);
    height: calc(100vh - var(--topnav-height));

    display: flex;
    flex-direction: column;

    border-right: 1px solid var(--color-sidenav-border);
    background-color: var(--color-sidenav-background);

    // Testing
    padding: 10px;
    gap: 5px;

    .event-list {
        cursor: pointer;
        // border-bottom: 1px solid var(--color-sidenav-border);

        > div { flex: 1 }

        .event-list-name {

        }

        .event-list-date {
            color: var(--color-body-subtle);
            font-size: var(--font-subtle-size);
        }
    }

    .navigation-item { 
        color: var(--color-body);
        padding: 10px 10px 10px 32px;
        display: flex;
        align-items: center;
        gap: 15px;
        transition: background-color .05s linear;
        text-decoration: none;

        // Testing
        padding: 8px 8px 8px 32px;
        border-radius: 5px;

        
        &:hover {
            background-color: var(--color-sidenav-hover);
        }

        &.selected {
            background-color: var(--color-sidenav-highlight);
        }
    }

    // Event Settings button
    .navigation-item:last-child {
        margin-top: auto;
        margin-bottom: 5px;
    }
}