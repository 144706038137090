
.cell-number {
    display: flex;
    height: 100%;
    align-items: center;

    .text-display {
        width: 100%;
        padding: 10px 8px 8px 8px;
        // text-align: right;
    }

    input {
        width: 100%;
        height: 100%;
        border: none;
        font-size: var(--font-body-size);
        padding: 8px;
        // text-align: right;

        &:focus, &:active, &:hover {
            border: none;
            outline: none;
        }
    }
}