
.page-toolbar {
    display: flex;
    gap: 10px;
    border-bottom: 1px solid var(--color-divider);
    // padding: 10px 20px;
    height: 55px;
    padding: 0 20px;
    align-items: center;

    .end-items {
        display: flex;
        margin-left: auto;
        gap: 15px;
    }
}