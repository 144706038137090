
.cell-select {
    height: 100%;
    padding: 3px 5px;

    select {
        font-size: var(--font-body-size);
        background-color: var(--grid-table-background);
        outline: none;
        padding: 0;
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 0;
    }
}