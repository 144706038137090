
.cell-text {
    // display: flex;
    // height: 100%;
    // align-items: center;

    .text-display {
        height: 100%;
        width: 100%;
        padding: 8px 8px 8px 8px;

        //padding: 7px 5px 5px 5px;
        // display: -webkit-box;
        // -webkit-line-clamp: 3;
        // -webkit-box-orient: vertical;
        // overflow: hidden;
    }

    input {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 0;
        font-size: var(--font-body-size);
        padding: 6px 8px 7px 8px;
        transition: none;

        margin-top: -1px;

        border: none;
        outline: none;
        background-color: transparent;

        &:focus, &:active, &:hover {
            border: none;
            outline: none;
            background-color: transparent;
        }
    }
}
