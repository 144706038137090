﻿// Fonts
$FontHeading: 'Montserrat', sans-serif;
//$FontHeading: 'Lato', sans-serif;
$FontBody: 'Roboto', sans-serif;

$BackgroundColour: #f5f5f5;
//$BackgroundColourHover: darken($BackgroundColour, 5%);
$BackgroundColourHover: rgba(100,0,0,0.05);
$BackgroundColourActive: darken($BackgroundColour, 15%);

$ErrorColour: #D04437;
$PanelColour: #fff;

$BackgroundDarkBlue: #313A4A;

// Brand Colours
$PrimaryColourLightAlt: #f8aea5;
$PrimaryColourLight: #ff6750;
$PrimaryColour: #e84930;
$PrimaryColourDark: #c52911;
$PrimaryColourDarkAlt: #360101;

$SecondaryColour: #1c2650;

$NeutralDark: #1f1f1f;
$Neutral: #333;
$NeutralLight: #a6a6a6;
$NeutralLightAlt: #ddd;

// Lines
$LineGrey: $NeutralLightAlt;

// Text
$TextBlack: $Neutral;
$TextGrey: $NeutralLight;
$TextWhite: #fff;
$TextLink: #e84930;

// Accents
$Accent1: #E84930;
//$Accent2: #75C353;
$Accent2: #1DA362;
$Accent3: #a55ea6;
$Accent4: #0fa0ce;
$Accent5: #FF0000;

$Accent6: #2196F3;

// Background
$Background: white;
$BackgroundHover: #f1f1f1;
$BackgroundGrey: #f1f1f1;
$BackgroundGreyHover: darken($BackgroundGrey, 10%);

// Left Navigation
$NavBackground: #1F2023;
$NavLine: #313131;
$NavLink: #838486;
$NavLinkActive: #ffffff;

$HeaderHeight: 64px;

// Media Queries
$mq-desktop: "only screen and (min-width: 1025px)";
$mq-tablet: "only screen and (max-width: 1024px)";
$mq-mobile: "only screen and (max-width: 767px)";

$mq-mobile-width: 767px;