
.message-bar {
    padding: 6px 10px;
    display: flex;
    align-items: center;
    gap: 10px;

    svg {
        width: 18px;
        height: 18px;
    }

    &.warning {
        background-color: var(--color-warning);
    }
}