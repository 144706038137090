@use "vars" as *;

h1, h2, h3, h4, h5, h6 {
    font-family: $FontHeading;
    color: var(--color-body);
    margin-top: 0;
}

h1 { 
    font-size: 3.0rem;
    line-height: 4.4rem;
    font-weight: 700;
}

h2 { 
    font-size: 2.2rem;
    line-height: 2.9rem;
    font-weight: 500;
}

h3 { 
    font-size: 1.9rem; 
    line-height: 2.5rem;
    font-weight: 500;
    margin-bottom: 10px;
}

h4 { 
    font-size: 1.5rem;
    line-height: 2.4rem;
    font-weight: 500;
}

h5 {
    font-size: 1.5rem;
    line-height: 2.4rem;
    font-weight: 400;
    color: #939393;
}

h6 {
    font-size: 1rem;
    line-height: 2.5rem;
    font-weight: 400;
    text-transform: uppercase;
    color: #939393;
}

label {
    font-weight: 500;
    // color: darken($TextGrey, 10%);
    color: var(--color-body-subtle);
    font-size: var(--font-body-size);
    display: block;
    margin-bottom: 5px;
}

input[type=text] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.bm-input {
    color: $TextBlack;
    border: 1px solid #e1e1e1;
    padding: 10px;
    background-color: $BackgroundColour;
    transition: .1s border ease-in-out, .1s background-color ease-in-out;
    box-shadow: none;
    outline: none;

    &:hover { background-color: $BackgroundColourHover; }
    &:active { background-color: $BackgroundColourActive; }
    &:focus { background-color: $BackgroundColour; }

    // &:hover {
    //     border: 1px solid #bbb;
    // }
}

// Remove tap highlight on iOS
input,
textarea,
button,
select,
label,
canvas,
a {
  -webkit-tap-highlight-color: transparent;
}

p {
    margin-top: 0;
    margin-bottom: 10px;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

// Break long words
* {
    -ms-word-break: break-all;
    word-break: break-all;

    word-break: break-word;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}