
.error-boundary {
    display: flex;
    width: 100vw;
    height: 100vh;
    padding: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    max-width: 960px;
    margin: 0 auto;
}
