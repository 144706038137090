@use "./vars" as *;

:root {
    color-scheme: light dark;

    --color-primary: #E84930;
    --color-background: #252627;
    --color-heading: #f5f4f3;
    --color-body: #f5f4f3;
    --color-body-black: #252627;
    --color-body-subtle: #a2a0a2;
    // --color-body-link: #689af3;
    --color-body-link: #8dbfff;
    --color-error: #ff5151;
    --font-body: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
    --font-heading: 'Montserrat', sans-serif;
    --font-body-size: 1.3rem;
    --font-subtle-size: 1.2rem;

    --sidenav-width: 260px;
    --topnav-height: 58px;

    --color-success: #094509;
    // --color-warning: #817400;
    --color-warning: #5e571e;
    --color-danger: #8a0000;

    --color-divider: #424244;
    //--color-input-background: #3B3B3B;
    --color-input-background: #121212;

    --color-sidenav-background: var(--color-background);
    --color-sidenav-hover: rgba(255,255,255,0.08);
    --color-sidenav-border: #424244;
    --color-sidenav-highlight: rgba(255,255,255,0.16);

    --color-topnav-background: transparent;
    --color-page-background: #121212;

    --grid-header-background: #121212; //#1e1f21
    --grid-header-background-hovered: #292929;
    --grid-table-background: #252627;
    --grid-table-background-hovered: #3a3b3d;
    --grid-menu-background: #1e1f21;
    --grid-menu-border: #424244;
    --grid-menu-button-hovered: #3a3b3d;
    --grid-menu-button-focus: #2C2C2E;
}

html {
    font-size: 62.5%;
}

html.light {
    --color-background: #f5f5f5;
    --color-heading: #333;
    --color-body: #333;

    --color-topnav-background: rgba(245,245,245,0.7);
}

body {
    font-family: var(--font-body);
    font-size: var(--font-body-size);
    background-color: var(--color-background);
    color: var(--color-body);
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    line-height: 1.6;
}

#app-root {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    > div {
        display: flex;
        // flex: 1 1 auto;
    }
}

*, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

a {
    // color:#1c2650;
    color: var(--color-body-link);
    text-decoration: none;

    &.text {
        color: var(--color-body);
    }

    &:hover, &:active {
        text-decoration: underline;
    }
}

.MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within {
    outline: none !important;
}

// Page
#dashboard-root {
    min-height: 100vh;
    padding-top: 64px;

    .frame {
        // width: 100%;
        padding-left: 260px;
        height: calc(100vh - 64px);
    }
}

button {
    color: var(--color-body);
    font-family: var(--font-body);
    font-weight: 500;
    padding: 8px 16px;
    border: 1px solid var(--color-divider);
    background-color: transparent;
    border-radius: 5px;
    cursor: pointer;
    transition: all .05s ease-out;

    display: flex;
    align-items: center;
    gap: 3px;

    &.small {
        padding: 4px 8px;
    }

    &:disabled {
        cursor: default;
    }
    
    &:not(:disabled) {

        &:hover, &:focus {
            border: 1px solid #888;
            background-color: rgba(255,255,255,0.05);
        }

        &:active {
            background-color: rgba(255, 255, 255, 0.12);
        }
    }

    &.danger {
        color: var(--color-error);
        border-color: var(--color-error);
    }

    &.link {
        border: none;
        font-weight: 400;
        color: var(--color-body-link);

        &:hover, &:focus {
            border: none;
            // text-decoration: underline;
            // background-color: transparent;
        }
    }

    &.MuiButtonBase-root:not(:disabled) {
        border: none;

        &:hover, &:focus {
            border: none;
        }
    }

    &[type="submit"] {
        background-color: #bcc4b6;
        color: #252627;

        &:not(:disabled):hover, &:not(:disabled):focus {
            background-color: darken(#bcc4b6, 10%);
        }

        &:disabled {
            background-color: darken(#bcc4b6, 30%);
        }
    }
}

input, select, textarea {
    color: var(--color-body);
    padding: 8px 12px;
    font-family: var(--font-body);
    font-size: var(--font-body-size);
    background-color: var(--color-input-background);
    border: 1px solid var(--color-divider);
    border-radius: 5px;
    outline: none;
    transition: all .05s ease-out;

    &:not(:disabled):hover, &:not(:disabled):focus {
        background-color: lighten(#121212, 5%);
        border: 1px solid #888;
    }
}

input {
    &::placeholder {
        color: var(--color-body-subtle);
    }
}

select {
    option {
        padding: 8px 12px;
        background-color: var(--color-input-background);
    }
}

textarea {
    resize: vertical;
}

hr {
    border: none;
    border-top: 1px solid var(--color-divider);
    margin: 12px 0;
}

form {
    display: flex;
    flex-direction: column;
    gap: 12px;

    h3 {
        margin: 30px 0 0 0;

        &:first-child {
            margin-top: 0;
        }
    }
}

.scrollable {
    scrollbar-width: auto;
    scrollbar-color: var(--color-divider) var(--grid-table-background);

    &::-webkit-scrollbar {
        // display: none;
        background-color: var(--grid-table-background);
        width: 18px;
        height: 18px;
    }

    &::-webkit-scrollbar-corner {
        background-color: var(--grid-table-background);
    }

    &::-webkit-scrollbar-track {
        background-color: var(--grid-table-background);
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--color-divider);
        border-radius: 10px;
        border: 5px solid transparent;
        background-clip: padding-box;
    }
}

.subtle {
    color: var(--color-body-subtle);
    font-size: var(--font-subtle-size);
}

.loader {
    width: 32px;
    height: 32px;
    border: 5px solid rgba(255,255,255,0.25);
    border-bottom-color: rgba(255,255,255,0.75);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 

// .loader {
//     width: 32px;
//     height: 32px;
//     border-radius: 50%;
//     display: inline-block;
//     position: relative;
//     border: 2px solid;
//     border-color: #FFF #FFF transparent;
//     box-sizing: border-box;
//     animation: rotation 1s linear infinite;
// }
    
// .loader::after {
//     content: '';  
//     box-sizing: border-box;
//     position: absolute;
//     left: 0;
//     right: 0;
//     top: 0;
//     bottom: 0;
//     margin: auto;
//     border: 2px solid;
//     border-color: transparent #FF3D00 #FF3D00;
//     width: 18px;
//     height: 18px;
//     border-radius: 50%;
//     animation: rotationBack 0.5s linear infinite;
//     transform-origin: center center;
// }

// @keyframes rotation {
//     0% {
//         transform: rotate(0deg);
//     }
//     100% {
//         transform: rotate(360deg);
//     }
// } 
        
// @keyframes rotationBack {
//     0% {
//         transform: rotate(0deg);
//     }
//     100% {
//         transform: rotate(-360deg);
//     }
// }  

@import "./fields.scss";

// .component-page {
//     padding: 20px;

//     &.framed {
//         max-width: 960px;
//         margin: 0 auto;
//     }
// }

// .form-control {
//     width: 100%;
//     border-radius: 0;
// }

// .form-control-inline {
//     padding: 5px;
//     border: 1px solid transparent;

//     &:hover {
//         border: 1px solid @LineGrey;
//     }
// }

// .form-group {
//     margin-bottom: 15px;

//     &.has-error input {
//         border-color: @ErrorColour;
//     }

//     .help-text {
//         visibility: hidden;

//         &.has-error {
//             visibility: visible;
//         }
//     }

//     &.inline {
//         display: inline-block;
//         margin-bottom: 0;

//         .form-control {
//             width: auto;
//         }
//     }
// }

// .hidden { 
//     display: none !important;
// }

// .subtitle {
//     color: @TextGrey;
//     font-size: 80%;
// }

// .help-text {
//     display: block;
//     color: @ErrorColour;
//     margin-top: 5px;

//     .glyphicon {
//         margin-right: 5px;
//     }
// }

// .glyphicon.white {
//     color: white;
// }

// .btn {
//     display: inline-block;
//     font-size: 14px;
//     font-weight: normal;
//     line-height: 1.42857143;
//     text-align: center;
//     white-space: nowrap;
//     vertical-align: middle;
//     -ms-touch-action: manipulation;
//     touch-action: manipulation;
//     cursor: pointer;
//     user-select: none;
//     color: @TextBlack;
//     padding: 8px 20px;
//     transition: .25s background-color;
//     text-decoration: none;
//     border: none;
//     border-radius: 0;
//     font-weight: 500;
//     outline: none;

//     span.glyphicon {
//         margin-right: 5px;
//     }

//     &.btn-default {
//         background-color: #eee;

//         &:hover {
//             background-color: darken(#eee, 10%);
//         }
//     }

//     &.btn-primary {
//         color: white;
//         background-color: @Accent1;

//         &:hover {
//             background-color: darken(@Accent1, 10%);
//         }
//     }
// }

// .badge {
//     padding: 2px 6px;
//     background-color: rgba(0,0,0,0.5);
//     border-radius: 5px;
//     // margin-left: 10px;
//     margin: 0 5px;
//     font-size: 1.4rem;
//     color: #fff;

//     &:first-child {
//         margin-left: 0;
//     }

//     &:last-child {
//         margin-right: 0;
//     }

//     &.badge-positive {
//         background-color: #28a745;
//         color: white;
//     }
// }

// .subtle {
//     color: @TextGrey;
// }

// header {
//     .header-buttons { right: 0; }
// }

// .main {
//     padding: 0;
// }

// @media @mq-mobile {
//     .modal-dialog {
//         margin: 120px auto;
//     }
// }

// .alert {
//     margin: 15px 0;
//     padding: 8px 12px;
//     border: none;
//     border-radius: 3px;
// }

// .alert.alert-danger {
//     background-image: none;
//     background-color: @ErrorColour;
//     color: white;
// }

// .alert.alert-success {
//     background-image: none;
//     background-color: #34A853;
//     color: white;
// }

// .metadata {
//     display: flex;

//     .metadata-labels {
//         margin-right: 20px;
//         text-align: right;
//     }

//     .metadata-content {
//         color: @TextBlack;
//     }

//     .metadata-labels,
//     .metadata-content {
//         > * {
//             margin-top: 10px;

//             &:first-child {
//                 margin-top: 0;
//             }
//         }
//     }

// }

// .back-button {
//     color: @PrimaryColour;
// }

// // table a {
// //     font-weight: 500;
// // }

// // -----------------------------------------------
// // ICONS
// // -----------------------------------------------

// li i {
//     margin-right: 10px;
// }

// i.white {
//     color: white;
// }

