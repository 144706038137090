
.drawer {
    position: fixed;
    top: var(--topnav-height);
    right: 0;
    min-width: 320px;
    width: 100vw;
    max-width: 600px;
    height: calc(100vh - var(--topnav-height));
    background-color: var(--color-background);
    z-index: 1000;
    border-left: 1px solid var(--color-divider);
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);

    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .drawer-title {
        padding: 10px 20px;
        font-size: 1.5rem;
        font-weight: 500;
        border-bottom: 1px solid var(--color-divider);
        position: sticky;
        z-index: 1;
        top: 0;
        background-color: var(--color-background);

        display: flex;
        align-items: center;

        h3 {
            flex: 1;
            margin: 0;
        }
    }

    .drawer-content {
        height: 100%;
        position: relative;
    }

    .drawer-loading {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: rgba(0,0,0,0.5);
        // backdrop-filter: blur(1px);
    }

    // .dialog {
    //     background-color: var(--color-background);
    //     padding: 20px;
    //     border-radius: 10px;
    //     min-width: 300px;
    //     min-height: 200px;

    //     box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    // }
}