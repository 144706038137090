
.component-page {
    // padding: 20px;
    // height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;

    &.framed {
        max-width: 960px;
        margin: var(--topnav-height) auto 0 auto;
    }
}

.page-header {
    padding: 20px 20px 0 20px;
    border-bottom: 1px solid var(--color-divider);

    h2 {
        margin: 0 0 15px 0;
    }
}

.page-content {
    // padding: 20px;
    overflow-y: auto;
}

.page {

    .content {
        transition: opacity 0.5s ease;
        &.loading {
            opacity: 0;
        }
    }

    > .content.framed {
        max-width: 1024px;
        margin: 0 auto;
    }

    .action-bar {
        flex: 0;
        display: flex;
        align-items: center;

        .button {
            display: flex;
            align-items: center;
            text-decoration: none;
            color: var(--color-body);

            i {
                font-size: 22px;
            }

            padding: 10px;
            min-width: 42px;
            height: 42px;
            margin-left: 10px;
            border-radius: 20px;

            &:hover {
                background-color: rgba(255,255,255,0.25);
            }

            span.glyphicon {
                margin-right: 0 !important;
            }
        }
    }
}