.grid-menu-item {
    padding: 8px 16px;
    // border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    user-select: none;

    //transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    svg {
        font-size: 18px;
    }

    &.disabled {
        cursor: auto;
        opacity: 0.3;
    }

    & *:first-child {
        opacity: 0.6;
    }

    &:not(.disabled):hover *:first-child,
    &:not(.disabled):active *:first-child {
        opacity: 1;
    }

    &:not(.disabled):hover {
        background-color: var(--grid-menu-button-hovered);
    }

    &:not(.disabled):active {
        background-color: var(--grid-menu-button-focus);
    }
}