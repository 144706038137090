.view-attendee-drawer {
    padding: 20px;
    // overflow-y: auto;

    .attendee-form {
        display: grid;
        gap: 10px;
        padding: 20px;
    }

    .attendee-drawer-header {
        h1 { margin: 0; }
        margin-bottom: 20px;
    }

    h4 {
        margin: 0;
    }

    .details-tab {
        padding: 20px 0;
    }

    .details-tab-grid {
        display: grid;
        gap: 20px;
    }

    hr { margin: 20px 0; }
    h4 { margin-bottom: 15px; }

    .loader-container {
        display: flex; 
        justify-content: center;
        align-items: center;
        min-height: 120px;
    }

    .regItem-container {
        display: flex;
        flex-direction: column;
        padding: 10px;
        border: 1px solid var(--color-divider);
        border-radius: 5px;
        background-color: var(--color-input-background);
    }
}

