
.auth-page {
    // padding: 30px 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    // background-color: #1c2650;
    // background-image: url("/images/auth-bg.svg"),-webkit-linear-gradient(45deg,rgba(88,0,0,.6),hsla(0,0%,100%,0));
    // background-image: url("/images/auth-bg.svg"),linear-gradient(45deg,rgba(88,0,0,.6),hsla(0,0%,100%,0));
    // background-position: 120% 50%,0 0;
    // background-size: 800px,auto;
    // background-repeat: no-repeat,repeat;
    // background-attachment: fixed,scroll;

    .auth-card {
        color: var(--color-body-black);
        max-width: 420px;
        background-color: white;
        padding: 20px;
        //box-shadow: 0 0 5px rgba(0,0,0,0.25);
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        border-radius: 10px;

        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .auth-hole {
        width: 60px;
        height: 10px;
        background-color: #281c3b;
        margin: 0 auto;
        border-radius: 40px;
    }

    .card-title {
        display: flex;
        flex-direction: column;
        align-items: center;

        > img {
            height: 48px;
        }
    }

    .logo {
        width: 240px;
    }

    .btn {
        font-size: 16px;
        width: 100%;
        border-radius: 3px;
        color: var(--color-body-black);
        border: 1px solid var(--color-divider);
        text-decoration: none;

        &:hover, &:active {
            background-color: var(--color-divider);
        }
    }

    .btn-primary {
        color: white;
        background-color: var(--color-primary);
        border: none;
        margin-bottom: 15px;

        &:hover, &:active {
            background-color: darken(#E84930, 10%);
        }
    }

    p {
        margin: 15px 0;
        color: var(--color-body-black);
    }

    a.forgot-link,
    a.signup-link {
        display: inline-block;
        color: var(--color-body-black);
        text-decoration: none;

        &:hover, &:active {
            text-decoration: underline;
        }
    }

    a.signup-link {
        float: right;
    }

    a.google-link {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--color-body-black);
        border: 1px solid var(--color-divider);

        &:hover, &:active {
            background-color: var(--color-divider);
        }

        img {
            height: 18px;
        }

        span {
            margin-left: 10px;
        }
    }

    hr {
        margin: 25px 0;
        border: none;
        border-top: 1px solid var(--color-divider);
    }

    .card-content {
        display: flex;
        flex-direction: column;
        min-height: 240px;
        justify-content: center;
        align-items: center;

        border-radius: 10px;
        // background-image: linear-gradient(90deg, rgba(230,230,230,1) 0%, rgba(220,220,220,1) 100%);
        border: 1px solid rgba(0,0,0,0.1);
        background-color: #eee;

        gap: 20px;
        font-weight: 500
        

        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;
        // min-height: 240px;

        // i {
        //     display: block;
        //     font-size: 22px;
        //     color: var(--color-body-black);
        // }

        // span {
        //     display: block;
        //     color: var(--color-body-black);
        //     margin-top: 10px;
        // }
    }
}

@media only screen and (max-width: 767px) {
    .auth-page {
        margin: 20px;

        .centered-panel {
            margin: auto;
        }
    }
}

#page-signup {
    #signup-form {
        text-align: center;
    }

    .g-recaptcha {
        display: inline-block;
    }
}