
.cell-date {
    height: 100%;

    .date-display {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 7px;
    }

    input {
        width: 100%;
        height: 100%;
        border: none;
        font-size: var(--font-subtle-size);
        padding: 5px;

        &:focus, &:active, &:hover {
            border: none;
            outline: none;
        }
    }
}