.view-activity-drawer {
    padding: 20px;
    // overflow-y: auto;

    .attendee-form {
        display: grid;
        gap: 10px;
        padding: 20px;
    }

    .activity-drawer-header {
        h1 { margin: 0; }
        margin-bottom: 20px;
    }

    h4 {
        margin: 0;
    }

    .details-tab {
        padding: 20px 0;
    }

    .details-tab-grid {
        display: grid;
        gap: 20px;
    }

    hr { margin: 20px 0; }
    h4 { margin-bottom: 15px; }
}
