﻿
.field-container {

}

.error {
    font-size: 1.2rem;
    color: var(--color-error);
}

.bm-field .error {
    margin-top: 3px;
    margin-left: 5px;
}