
.page-tabs {
    display: flex;
    gap: 20px;

    a.tab {
        color: var(--color-body-subtle);
        padding: 5px 0;
        transition: .05s color;
        text-decoration: none;

        &:hover {
            color: var(--color-body);
        }

        &.selected {
            color: var(--color-body);
            border-bottom: 3px solid var(--color-body);
        }
    }
}