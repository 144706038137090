
.chip {
    padding: 5px 8px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.1);

    &.chip-primary {
        background-color: var(--color-primary);
    }

    &.chip-success {
        background-color: var(--color-success);
    }

    &.chip-danger {
        background-color: var(--color-danger);
    }

    &.chip-warning {
        background-color: var(--color-warning);
    }
}