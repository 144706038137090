
.grid-root {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;

    background-color: #161616;

    .grid-spinner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.6);
        display: flex;
        align-items: center;
        justify-content: center;
        display: none;
        z-index: 1000;
        opacity: 0;
        transition: opacity .1s, display .1s allow-discrete;
        // backdrop-filter: blur(5px);

        &.visible { 
            display: flex;
            opacity: 1;
        }
    }

    .grid-footer {
        background-color: var(--grid-header-background);
        padding: 8px;
        
        position: sticky;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 11;

        .grid-footer-left {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }
}

@starting-style {
    .grid-root .grid-spinner {
        opacity: 0;
    }
}

.grid {
    position: relative;
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex: 1;

    .grid-column-ghost {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(255,255,255,0.1);
        cursor: grabbing;
        z-index: 20;
        padding: 5px;
    }

    &.scrolled .grid-header-item.pinned,
    &.scrolled .grid-row-item.pinned {
        box-shadow: 4px 0 0 0 rgb(0 0 0 / 20%);
    }

    // &.pinned-grid {
    //     position: sticky;
    //     left: 0;
    //     z-index: 5;

    //     .grid-header {
    //         padding-right: 0;
    //     }
    // }

    .grid-header {
        display: grid;
        position: sticky;
        top: 0;
        z-index: 11;
        // background-color: var(--grid-header-background);
        // padding-right: 60px;
    }

    .grid-header-item {
        padding: 8px;
        position: relative;
        font-size: var(--font-body-size);
        display: flex;
        align-items: center;
        gap: 5px;
        user-select: none;
        border-left: 1px solid var(--color-divider);
        touch-action: pan-y;
        background-color: var(--grid-header-background);

        &:first-child { border-left: none; }
        &:last-child { border-right: 1px solid var(--color-divider) }
        &:hover { background-color: var(--grid-header-background-hovered); }
        &.pinned { position: sticky; z-index: 10; left: 0; &:hover { z-index: 15; } }

        .header-label {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            
            display: flex;
            align-items: center;
            svg {
                margin-left: 5px;
                color: var(--color-primary)
            }
        }

        .header-menu-button {
            margin-left: auto;
            height: 18px;
            font-size: 1.5rem;
            padding: 0 3px;
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        .resize-control {
            position: absolute;
            right: -10px;
            top: 0;
            bottom: 0;
            width: 20px;
            cursor: ew-resize;
            z-index: 2;

            &:hover::after {
                content: "";
                position: absolute;
                top: 3px;
                bottom: 3px;
                left: 9px;
                width: 3px;
                background-color: red;
            }
        }
    }

    .grid-header-add {
        width: 100px;
        font-size: 1.8rem;
        justify-content: center;
        cursor: pointer;
    }

    .grid-rows {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1;
    }

    .grid-row {
        display: grid;
        cursor: default;
        background-color: var(--grid-table-background);

        &:hover {
            background-color: var(--grid-table-background-hovered);
        }
    }

    .grid-row-item {
        border-bottom: 1px solid var(--color-divider);
        border-left: 1px solid transparent;
        font-size: var(--font-body-size);
        // background-color: var(--grid-table-background);
        user-select: none;
        position: relative;
        height: 40px;

        // Test this
        //display: -webkit-box;
        //-webkit-line-clamp: 3;
        //-webkit-box-orient: vertical;
        overflow: hidden;
        //max-height: 80px;

        .grid-item-content { padding: 8px 10px; }

        &:first-child { border-left: none; }
        &:last-child { border-right: 1px solid var(--color-divider); }
        //&.hovered { background-color: rgba(255,255,255,0.06); }
        // &.hovered { background-color: var(--grid-table-background-hovered); }
        &.saving { ::after { content: "" } }
        &.pinned { position: sticky; z-index: 10; &.cursor { z-index: 15; } }

        &.cursor { box-shadow: inset rgb(40, 114, 225) 0px 0px 0px 2px !important; background-color: var(--grid-table-background); }
        //&.editing { box-shadow: inset rgb(40, 114, 225) 0px 0px 0px 2px !important; background-color: var(--grid-table-background); }

        &.readonly.cursor { box-shadow: inset rgb(140, 140, 140) 0px 0px 0px 2px !important; background-color: var(--grid-table-background); }
        // &.readonly.editing { box-shadow: inset rgb(140, 140, 140) 0px 0px 0px 2px !important; background-color: var(--grid-table-background); }
    }

    &.editable .grid-row-item:not(:first-child) {
        border-left: 1px solid var(--color-divider);
    }

    .grid-row-item-menu {
        font-size: 16px;
        position: absolute;
        border-radius: 100%;
        top: 7px;
        right: 5px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background-color: var(--grid-table-background-hovered);
        }
    }

    .grid-row-item-end {
        border-left: 1px solid var(--color-divider);
    }

    .ellipsis {
        // max-width: 100%;
        // text-overflow: ellipsis;
        // overflow: hidden;
        // white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        position: relative;

        mask-image: linear-gradient(to right, white 0 85%, transparent);
        
        // &:after {
        //     content: "";
        //     position: absolute;
        //     right: 0;
        //     top: 0;
        //     bottom: 0;
        //     width: 30px;
        //     //background-color: red;
        //     background-image: linear-gradient(to right, rgba(37,38,39,0), rgba(37,38,39, 1));
        // }
    }

    // .grid-row-item.hovered .ellipsis:after {
    //     background-image: linear-gradient(to right, rgba(58,59,61,0), rgba(58,59,61, 1));
    // }

}
