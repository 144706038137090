
.dialog-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(1px);

    .dialog {
        position: relative;
        background-color: var(--color-background);
        padding: 20px;
        border-radius: 5px;
        // min-width: 420px;
        min-height: 200px;
        border: 1px solid var(--color-divider);

        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);

        display: flex;
        flex-direction: column;

        &.small {
            width: 300px;
        }

        &.medium {
            width: 420px;
        }

        &.large {
            width: 720px;
        }
    }

    .dialog-content {
        flex: 1;
    }

    .dialog-footer {
        display: flex;
        justify-content: flex-end;
        gap: 5px;
    }

    .dialog-loader {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0,0,0,0.5);
    }
}