
.reg-items-table {

    .grid-header {
        // margin-bottom: 10px;
        border-bottom: 3px solid var(--color-divider);
    }

    .grid-header-item {
        background-color: transparent;
        border: none;
        border-right: none !important;
    }

    .table-root {
        background: transparent;
    }

    .table .grid-row-item {
        height: 48px;
        padding-left: 8px;

        display: flex;
        align-items: center;
        background-color: transparent;
        // border: none;
        // background-color: transparent;
    }

    .table .grid-row:nth-child(2n) {
        // border: 1px solid var(--color-divider);
        // border-radius: 5px;
        // margin-bottom: 5px;

        background-color: rgba(32,33,34,1);

        background-color: #202122;
    }

    .table .grid-row:hover {
        background-color: var(--grid-table-background-hovered);
    }

}