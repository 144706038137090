@use "../vars" as *;

#page-signup {
    padding-top: 20px;
    box-shadow: none;

    .centered-panel {
        max-width: 420px;
        background-color: white;
        margin: auto;
        padding: 25px;
        /*border: 1px solid #ddd;*/
        box-shadow: 0 0 5px rgba(0,0,0,0.25);


        .panel-title {
            h1 { margin: 0; }   
        }

        p {
            margin: 0;
        }
    }

}