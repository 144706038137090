.grid-menu {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10000;

    background-color: var(--grid-menu-background);
    border-radius: 5px;
    min-width: 240px;
    border: 1px solid var(--color-divider);
    // padding: 5px;
    padding: 5px 0;

    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
}