
.topnav {
    display: flex;
    align-items: center;

    // position: fixed;
    // top: 0;
    // left: 0;
    // right: 0;
    flex: 0 0 auto;
    height: var(--topnav-height);
    z-index: 11;
    padding: 0 20px;

    backdrop-filter: blur(6px);
    background-color: var(--color-topnav-background);
    border-bottom: 1px solid var(--color-divider);

    .header-nav {
        display: flex;
        flex: 1;
        align-items: center;
        height: 100%;
    }

    .header-buttons {
        height: 100%;
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        gap: 10px;
    }

    .header-logo {
        display: flex;
        align-items: center;

        img { 
            height: 100%;
        }
    }
}