
.bm-field {

    &.horizontal {
        display: flex;
        gap: 10px;
        flex-direction: row;
        align-items: center;

        label {
            flex: 0 0 140px;
            margin-bottom: 0;
        }
    }

    &.vertical {
        display: flex;
        flex-direction: column;
    }

    .subtle {
        margin-top: 5px;
    }

    input {
        max-width: 420px;
    }

    select {
        min-width: 160px;
    }

    .bm-field-area {
        border: 1px solid var(--color-divider);
        padding: 10px;
        border-radius: 5px;
        background-color: var(--color-input-background);
    }
}

.field-container {
    flex: 1;
}