.reg-addon-drawer {
    padding: 20px;
    overflow-y: auto;

    .attendee-form {
        display: grid;
        // gap: 10px;
        padding: 20px;
    }

    h4 {
        margin: 0;
    }
}