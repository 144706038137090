
.component-tabs {

    .tabs {
        display: flex;
        gap: 10px;
        border-bottom: 1px solid var(--color-divider);

        button {
            border-radius: 0;
            border: none;
            //border-bottom: 2px solid var(--color-divider);
            border-bottom: 2px solid transparent;
    
            &:hover, &:active {
                border: none;
                border-bottom: 2px solid transparent;
            }
    
            &:focus {
                border: none;
                border-bottom: 2px solid transparent;
                background-color: transparent;
            }
    
            &.active {
                border-bottom: 2px solid var(--color-primary);
            }
        }
    }
}